<template>
  <div class="reserve-submit">
    <h3 class="title" v-if="!loadStatus && !error.length">
      {{ loading }}
    </h3>
    <h3 class="title error-text" v-if="error.length">
      {{ error }}
    </h3>
    <h3 class="title success-text" v-if="resultMsg.length">
      {{ resultMsg }}
    </h3>
  </div>
</template>

<style lang="scss" scoped>
h3.title {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: #ebebeb;
  color: #222;
  margin-bottom: 30px;

  &.error-text {
    background-color: rgba(red, 0.6);
  }

  &.success-text {
    background-color: rgba(green, 0.6);
  }
}
</style>

<script>
import apiService from "@/api/service.js";

export default {
  name: "Receive/Submit",
  data() {
    return {
      loading: "Hold tight, loading...",
      loadStatus: false,
      error: "",
      resultMsg: "",
    };
  },
  mounted() {
    this.reserve();

    setTimeout(() => {
      if (!this.loadStatus) {
        this.loading = "It is taking longer than expected...";

        setTimeout(() => {
          if (!this.loadStatus) {
            this.loading = "Sorry for the inconvenience, still loading...";
          }
        }, 4000);
      }
    }, 2000);
  },
  methods: {
    pollDrawer: async function (drawerId, maxTry = 120, errorCount = 0) {
      if (maxTry <= 0) {
        return false;
      }

      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const status = await apiService.getSolenoidStatus(drawerId);
        if (status == false) {
          return true;
        }

        return await this.pollDrawer(drawerId, maxTry - 1, errorCount);
      } catch (err) {
        if (errorCount >= 15) {
          return false;
        }

        return await this.pollDrawer(drawerId, maxTry, errorCount + 1);
      }
    },
    pollDrawerOpen: async function (drawerId, maxTry = 30, errorCount = 0) {
      if (maxTry <= 0) {
        return false;
      }

      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const status = await apiService.getSolenoidStatus(drawerId);
        if (status == true) {
          return true;
        }

        return await this.pollDrawerOpen(drawerId, maxTry - 1, errorCount);
      } catch (err) {
        if (errorCount >= 15) {
          return false;
        }

        return await this.pollDrawerOpen(drawerId, maxTry, errorCount + 1);
      }
    },
    reserve: async function () {
      const sizeCodes = ["s", "m", "l", "xl"];

      let barcode = this.$route.query.barcode;

      if (barcode.length <= 0) {
        this.error =
          "There are one or more empty fields in the form, try again.";
        return;
      }

      try {
        const result = await apiService.withdrawPackageStep1(barcode);

        if (!result) {
          throw "No package found!";
        }

        console.log(result);

        this.loadStatus = true;
        this.resultMsg = "Done, please wait until the drawer is opened...";

        const drawerId = result;
        const drawerData = await apiService.getDrawer(drawerId);

        if (drawerData.blackout_state == 1) {
          this.resultMsg = "";
          this.error = `Drawer ${drawerId} is currently in blackout state, please try getting this package later and contact your system administrator.`;
          return;
        }

        try {
          const open = await apiService.openDrawer(drawerId);
        } catch (err) {
          this.resultMsg =
            "System error while opening the drawer, please contact system administrator.";
          return;
        }

        this.resultMsg = "Opening the drawer, please wait...";

        // Poll the drawer until it is opened
        const pollOpen = await this.pollDrawerOpen(drawerId);
        if (!pollOpen) {
          this.resultMsg =
            "The drawer is not opened, please try again later or contact system administrator.";
          return;
        }

        this.resultMsg =
          "Opened the drawer, please get your package and close it.";

        await new Promise((resolve) => setTimeout(resolve, 5000));

        const poll = await this.pollDrawer(drawerId);
        if (!poll) {
          this.resultMsg =
            "There was a system error, please close the drawer after getting your package.";
          return;
        }

        this.resultMsg = "Thanks you for choosing us, have a nice day!";
      } catch (error) {
        console.log(error);
        this.error = "There is a system error occured. Please try again later.";
        return;
      }

      await apiService.withdrawPackageStep2(barcode);

      setTimeout(() => {
        this.$router.push({
          name: "Home",
        });
      }, 10000);
      return;
    },
  },
};
</script>
